<template>
  <div class="page-ladies-classes">
    <Banner :image-versions="featuredImageVersions">
      Brazilian Jiu-Jitsu in Surrey
      <br /><br />
      Claim your FREE first womans BJJ lesson
      <br />
      <RouterLink class="btn" :to="{name: 'contact'}">Get in touch</RouterLink>
    </Banner>

    <WrappedSection slug="ladies-classes" className="is-odd" />

    <Section class="is-even">
      <h2>Ladies BJJ classes timetable</h2>

      <ClassDataWrapper :filter="['Ladies']" />
    </Section>

    <Section class="is-odd">
      <Carousel
        :navigationEnabled="true"
        :perPage="1"
        :paginationEnabled="true"
        :autoplay="true"
        :autoplay-timeout="8000"
        :autoplay-hover-pause="true"
        :loop="true"
        class="carousel"
      >
        <Slide
          class="slide"
          v-for="testimonial in testimonials"
          :key="testimonial.id"
        >
          <Testimonial :testimonial="testimonial"></Testimonial>
        </Slide>
      </Carousel>
    </Section>

    <LocationSection className="is-even" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Banner from '@/components/Banner'
import ClassDataWrapper from '@/components/ClassDataWrapper'
import Section from '@/components/Section'
import WrappedSection from '@/components/WrappedSection'
import LocationSection from '@/components/LocationSection'
import Testimonial from '@/components/Testimonial'

import {Carousel, Slide} from '@/lib/vue-carousel'

const testimonials = [
  {
    id: 1,
    content:
      "I'm so grateful to have started my jiu jitsu journey at the Koba women's class. Starting at the women's class made the introduction to jiu jitsu a whole lot less intimidating, and a whole lot of fun!  I went there to learn bjj not realising how many other benefits would come along side that. I've never been in a gym environment that feels as welcoming as Koba does. You know that everyone in there is rooting for you and you are there to all learn together.  You won't ever regret showing up to a session that's for sure.",
    author: 'Evie',
  },
  {
    id: 2,
    content:
      "I started Jiujitsu initially to see why my husband was so buzzing from training and it's the best thing I have done. It's great to learn a martial art while getting fit, and it has driven my confidence up tenfold. Plus Koba bjj is the ideal place to train, as the coaches are skilled and knowledgeable and there is a girls only class. Plus it's fun and most importantly supportive.",
    author: 'Gemma',
  },
  {
    id: 3,
    content:
      "I wish I could say something profound but … I just really bloody love it! It's like an msg sport - it's addictive. It's great to be part of a sisterhood, women supporting women… girl power!!!",
    author: 'Hannah',
  },
]

export default {
  name: 'LadiesClasses',

  mixins: [page],

  data() {
    return {
      pageSlug: 'ladies-classes',
      testimonials,
    }
  },

  components: {
    Banner,
    Carousel,
    ClassDataWrapper,
    LocationSection,
    Section,
    Slide,
    Testimonial,
    WrappedSection,
  },
}
</script>

<style lang="sass" scoped>
.page-ladies-classes

  .carousel

    .slide
      display: flex
      justify-content: center
      align-items: center
</style>
