import { render, staticRenderFns } from "./LadiesClasses.vue?vue&type=template&id=6b5e43c7&scoped=true&"
import script from "./LadiesClasses.vue?vue&type=script&lang=js&"
export * from "./LadiesClasses.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./LadiesClasses.vue?vue&type=style&index=0&id=6b5e43c7&prod&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6b5e43c7",
  "6c97d1a6"
  
)

export default component.exports